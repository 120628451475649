<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="800"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t('invoices.general.send_invoice') }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-combobox
              v-model="item.emails"
              :label="formMixin_getRequiredFieldLabel($t('invoices.recipient_emails'))"
              :error-messages="errors.email"
              multiple
              chips
              deletable-chips
              small-chips
              hide-selected
              @input="clearErrors('email')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <ProjectAutocomplete
              v-model="item.project_id"
              :initial-item="item.project"
              @update:initial-item="setProject"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              v-if="item.project"
              v-model="item.sprints"
              :items="sprints"
              :label="$t('projects.general.sprints')"
              :loading="loading['get:api/project-sprints']"
              item-text="sprint_name"
              item-value="id"
              return-object
              deletable-chips
              clearable
              hide-no-data
              hide-selected
              small-chips
              multiple
              chips
              @input="setComment"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="item.comment"
              :error-messages="errors.comment"
              :label="$t('general.comment')"
              name="comment"
              auto-grow
              @input="clearErrors('comment')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-subheader class="pa-0">
              {{ $t('general.locale.choose_language') }}
            </v-subheader>
            <v-radio-group v-model="lang" :column="false" style="margin-top: 0">
              <v-radio :label="$t('general.locale.lithuanian')" class="mr-3" value="lt"></v-radio>
              <v-radio :label="$t('general.locale.english')" value="en"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click.native="close">
          {{ $t('general.controls.cancel') }}
        </v-btn>
        <v-btn
          :disabled="sendingInvoice"
          :loading="sendingInvoice"
          color="primary"
          text
          @click="send"
        >
          {{ $t('general.controls.send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getSprintUserStorySummary } from '@/util/sprint-summary';
import projectService from '@/api/project-service';
import { mapGetters, mapState } from 'vuex';
import formMixin from '@/mixins/form-mixin';
import projectSprintService from '@/api/project-sprint-service';
import invoiceService from '@/api/invoice-service';
import { mapErrorsToInputs } from '@/util/forms';
import { openSnackbar } from '@/util/event-bus';
import ProjectAutocomplete from '@/components/autocompletes/ProjectAutocomplete';
import clone from 'just-clone';

export default {
  name: 'SendInvoice',

  components: {
    ProjectAutocomplete,
  },

  mixins: [formMixin],

  data() {
    return {
      item: {},
      lang: 'lt',
      sprints: [],
      errors: {},
    };
  },

  computed: {
    ...mapState('invoices', ['invoiceForSending']),
    ...mapGetters(['loading']),

    invoiceId() {
      return +this.$route.params.invoiceId;
    },

    sendingInvoice() {
      return this.loading[`post:api/finances/invoices/${this.invoiceId}/send/via-email`];
    },
  },

  created() {
    this.item = clone(this.invoiceForSending);
    if (this.item.project) {
      this.getProjectSprints(this.item.project.id);
    }
  },

  methods: {
    searchProjects: projectService.search,

    async getProjectSprints(projectID) {
      const { data } = await projectSprintService.getAll({ project_id: projectID });
      this.sprints = data;
      this.sprints.sort((a, b) => (b.deadline > a.deadline ? 1 : -1));
    },

    setProject(project) {
      this.item.project = project;
      if (project) {
        this.getProjectSprints(project.id);
      }
    },

    setComment() {
      let text = '';
      for (let i = 0; i < this.item.sprints?.length; i++) {
        text += getSprintUserStorySummary(this.item.sprints[i]);
      }
      this.item.comment = text;
    },

    async send() {
      const requests = [];
      for (let i = 0; i < this.item.emails.length; i++) {
        const payload = {
          email: this.item.emails[i],
          comment: this.item.comment,
        };
        requests.push(invoiceService.sendViaEmail(this.invoiceId, payload, this.lang));
      }

      try {
        await Promise.all(requests);
        openSnackbar(this.$t('invoices.general.invoice_sent'));
        this.close();
      } catch (e) {
        this.errors = mapErrorsToInputs(e);
      }
    },

    close() {
      this.$router.push(this.$route.meta.getBackRoute());
    },

    clearErrors(key) {
      if (this.errors[key]) {
        delete this.errors[key];
      }
    },
  },
};
</script>
